<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">REVIEWS</h1>
            </div>
            <div class="box b-shdw-3">
                <p v-html="content.main"/>
                <div class="level-item tp-item">
                    <trustpilot-widget />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";
    import TrustpilotWidget from '../components/common/trustpilot/TrustpilotWidget';

    export default {
        name: "Reviews",
        mixins: [ contentAPI, page ],
        components: {
            TrustpilotWidget
        }
    }    
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
    }
    .level-item {
        padding: 20px 0 40px 0;
    }
    p {
        margin: 0 0 15px 0;
    }
</style>